<template>
<div class="row option">
    <div class="col-6">
      <div class="tooltip-box tooltip-box--price mb-2">
        <span v-if="option.discount > 0" class="badge-sale">{{ option.discount }}%</span>
        <tooltip-btn :tooltip="option.tooltip"/>
      </div>
      <img :src="$filters.image(option.image, '190x')" class="img-fluid"/>
    </div>
    <div class="col-6">
      <div class="option__name mb-2">{{ option.name }}</div>
      <span v-if="!option.sub_options">
        <div v-if="option.discount > 0" class="option__price-box mb-2">
          <span class="option__price option__price--discount">{{ $filters.currency(price) }}</span>
          <span class="option__price option__price--old">{{ $filters.currency(oldPrice) }}</span>
        </div>
        <div v-else class="option__price-box mb-2">
          <span class="option__price">{{ $filters.currency(price) }}</span>
        </div>
      </span>
      <div v-if="option.sub_options" class="option__controls mb-2">
        <span v-for="item in option.sub_options" :key="item.id">
          <div v-if="item.type === 'check'" class="form-check">
            <input class="form-check-input" type="checkbox" :id="`sub-option-${item.id}`" :value="item.id" @change="selectSubOption({ optionId: option.id, subOptionId: item.id, e: $event })" v-model="item.selected">
            <label class="form-check-label" :for="`sub-option-${item.id}`">
              {{ item.name }}
            </label>
          </div>
        </span>
      </div>
      <button class="btn btn-primary" :class="[option.selected ? 'selected' : 'not-selected']" @click="onClickOption">
        <span v-if="option.selected">Ausgewählt</span>
        <span v-else>Auswählen</span>
      </button>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
import TooltipBtn from '@/pages/configurator/common/tooltipBtn.vue'
export default {
  name: 'SelectOption',
  components: { TooltipBtn },
  props: {
    option: {
      required: true,
      type: Object
    }
  },
  setup (props) {
    const store = useStore()
    const priceAll = computed(() => {
      const currentStep = store.getters['configurator/currentStep']
      return currentStep.price_all || false
    })
    onMounted(() => {
      if (priceAll.value) {
        store.dispatch('configurator/calculatePriceAll', { option: props.option })
      }
    })
    const totalPrice = computed(() => {
      const totalPrice = store.getters['configurator/totalPrice']
      return totalPrice(props.option)
    })
    const price = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.discounted || totalPrice.value.total
      }
      if (option.price) {
        if (!isNaN) {
          return option.price.discounted
        } else {
          if (option.discount > 0) {
            const discount = (Number.parseFloat(option.discount) / 100) * option.price.self
            let totalDiscount = 0
            totalDiscount = option.price.self - discount
            return totalDiscount
          } else {
            return option.price.self
          }
        }
      }
      return 0
    })
    const oldPrice = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.old_price
      }
      if (option.price) {
        return option.price.self
      }
      return 0
    })
    function onClickOption () {
      if (props.option.disabled) {
        return
      }
      if (props.option.type === 'check') {
        store.dispatch('configurator/toggleOption', props.option)
      } else {
        store.dispatch('configurator/selectOption', props.option)
      }
    }
    function selectSubOption ({ optionId, subOptionId, e }) {
      store.dispatch('configurator/selectSubOption', { optionId: optionId, subOptionId: subOptionId, e: e })
    }
    return {
      price,
      oldPrice,
      onClickOption,
      selectSubOption
    }
  }
}
</script>
